import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import type { MessageKeys } from '../translations';
import { useLocalizedFileContent } from '../utils';
import MuiReactMarkdown from './MuiReactMarkdown';

export interface Props {
    id: MessageKeys;
    values?: { [key: string]: any };
}

const LocalizedMarkdown: React.FC<Props> = ({ id, values }) => {
    const md = useLocalizedFileContent(id, values);

    if (md === null) {
        return <CircularProgress />;
    }

    return <MuiReactMarkdown>{md}</MuiReactMarkdown>;
};

export default LocalizedMarkdown;
