import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DataSources from './scenes/DataSources';
import Home from './scenes/Home';
import Keys from './scenes/Keys';

const RedirectToHome = () => (
    <Redirect to="/home" />
);

const Routes: React.FunctionComponent = () => (
    <Switch>
        <Route path="/home" component={Home} />
        <Route path="/data-sources" component={DataSources} />
        <Route path="/keys" component={Keys} />
        <Route path="*" component={RedirectToHome} />
    </Switch>
);

export default Routes;
