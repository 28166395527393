import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import type { WFState } from '@realcity/web-frame';
import type { KeycloakInstance } from 'keycloak-js';
import React, { useRef, useState } from 'react';
import type { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';

const GraphqlWrapper: React.FC = ({ children }) => {
    const keycloakRef = useRef<KeycloakInstance>();
    keycloakRef.current = useSelector((state: WFState): KeycloakInstance => state.auth.keycloak!);
    const [client] = useState(() => createClient(keycloakRef));

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
};

export default GraphqlWrapper;

function createClient(keycloakRef: MutableRefObject<KeycloakInstance | undefined>): ApolloClient<unknown> {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach((error) => {
                // eslint-disable-next-line no-console
                console.error('[GraphQL error]', error);
            });
        }
        if (networkError) {
            // eslint-disable-next-line no-console
            console.error('[Network error]', networkError);
        }
    });
    const authLink = setContext(async (request, previousContext) => {
        const keycloak = keycloakRef.current;
        if (!keycloak) {
            throw new Error('Keycloak is not available!');
        }
        await keycloak.updateToken(30);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
            ...previousContext,
            headers: {
                ...previousContext.headers,
                Authorization: `Bearer ${keycloak.token!}`,
            },
        };
    });
    const httpLink = new HttpLink({ uri: '/graphql' });

    return new ApolloClient({
        link: ApolloLink.from([
            errorLink,
            authLink,
            httpLink,
        ]),
        cache: new InMemoryCache(),
    });
}
