import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmButton from '../../components/ConfirmButton';
import { useDeleteKeyMutation } from '../../graphql/graphql-typings';

interface Props {
    keyToDelete: string;
    onDelete: () => void;
}

const DeleteKeyButton: React.FC<Props> = ({ keyToDelete, onDelete }) => {
    const [executeDeleteKey, deleteKeyResult] = useDeleteKeyMutation({
        onCompleted: onDelete,
    });

    const deleteKey = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeDeleteKey({
            variables: {
                key: keyToDelete,
            },
        });
    };

    if (deleteKeyResult.loading) {
        return (
            <CircularProgress />
        );
    }

    return (
        <ConfirmButton
            confirmMessage={<FormattedMessage id="keys.delete.confirm" values={{ key: keyToDelete }} />}
            onConfirm={deleteKey}
        >
            <FormattedMessage id="keys.delete" />
        </ConfirmButton>
    );
};

export default DeleteKeyButton;
