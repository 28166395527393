import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ContentContainer from '../components/ContentContainer';
import LocalizedMarkdown from '../components/LocalizedMarkdown';
import APP_CONFIG from '../config';

const BASE_URL = APP_CONFIG.API_BASE_URL;

const DataSources: React.FC = () => (
    <>
        <Typography variant="h1">
            <FormattedMessage id="data-sources.title" />
        </Typography>

        <ContentContainer>
            <LocalizedMarkdown id="data-sources.content" values={{ baseUrl: BASE_URL, localUrl: window.location.origin }} />
        </ContentContainer>
    </>
);

export default DataSources;
