import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ContentContainer from '../components/ContentContainer';
import LocalizedMarkdown from '../components/LocalizedMarkdown';
import header from '../images/header.png';

const useStyles = makeStyles({
    headerImage: {
        aspectRatio: 'auto 1112 / 240',
        borderRadius: '0 0 8px 8px',
        width: '100%',
    },
});

const Home: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <img
                src={header}
                alt="illustration"
                className={classes.headerImage}
            />
            <Typography variant="h1" sx={{ marginY: 2 }}>
                <FormattedMessage id="home.title" />
            </Typography>
            <ContentContainer>
                <LocalizedMarkdown id="home.content" />
            </ContentContainer>
        </>
    );
};

export default Home;
