import type { ThemeOptions } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';
import { configureStore } from '@realcity/web-frame';
import { AuthWrapper } from '@realcity/web-frame/lib/components/Auth';
import { Base } from '@realcity/web-frame/lib/components/Base';
import IntlProvider from '@realcity/web-frame/lib/components/Intl/Provider';
import CustomTheme from '@realcity/web-frame/lib/theme/CustomTheme';
import Light from '@realcity/web-frame/lib/themes/Light';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import MainMenu from './components/MainMenu';
import GraphqlWrapper from './providers/GraphqlWrapper';
import Routes from './Routes';
import messages from './translations';

const store = configureStore({});
const authOptions = {};

const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiTypography: {
            variants: [{
                props: { variant: 'h1' },
                style: {
                    fontSize: 32,
                    marginBottom: 12,
                    fontWeight: 700,
                },
            }, {
                props: { variant: 'h2' },
                style: {
                    fontSize: 28,
                    marginTop: 20,
                },
            }],
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '0 24px 20px',
                },
            },
        },
    },
    pagePadding: 4,
};

const theme = createTheme(themeOptions, Light);

const App: React.FC = () => (
    <Provider store={store}>
        <IntlProvider supportedLocales={['en', 'hu']} messages={messages}>
            <AuthWrapper options={authOptions}>
                <BrowserRouter>
                    <GraphqlWrapper>
                        <CustomTheme theme={theme}>
                            <Base
                                appName={<FormattedMessage id="app.title" /> as any}
                                appVersion={process.env.REACT_APP_VERSION!}
                                useFeedback={false}
                                menu={<MainMenu />}
                                sidebarExpandType="NORMAL"
                                openByDefault
                                usePageFrame
                            >
                                <Routes />
                            </Base>
                        </CustomTheme>
                    </GraphqlWrapper>
                </BrowserRouter>
            </AuthWrapper>
        </IntlProvider>
    </Provider>
);

export default App;
