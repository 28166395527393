import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCreateKeyMutation } from '../../../graphql/graphql-typings';

interface Props {
    onClose: () => void;
    onCreated: () => void;
}

const AddKeyPanel: React.FC<Props> = ({ onClose, onCreated }) => {
    const [comment, setComment] = useState('');
    const [companyName, setCompanyName] = useState('');

    const [executeCreateKey, createKeyResult] = useCreateKeyMutation({
        update: onCreated,
    });

    const createKey = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeCreateKey({
            variables: {
                options: {
                    comment,
                    companyName: companyName || null,
                },
            },
        });
    };

    if (createKeyResult.loading) {
        return (
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        );
    }

    if (createKeyResult.data) {
        return ( // TODO extract component
            <>
                <DialogContent>
                    <Typography>
                        <FormattedMessage id="keys.add.success" values={{ key: createKeyResult.data.createKey.key }} />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onClose}>
                        <FormattedMessage id="message.ok" />
                    </Button>
                </DialogActions>
            </>
        );
    }

    return (
        <>
            <DialogContent>
                <Typography>
                    <FormattedMessage id="keys.add.description" />
                </Typography>
                <TextField
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    margin="dense"
                    value={companyName}
                    label={<FormattedMessage id="api-key.company-name" />}
                    onChange={(e) => {
                        setCompanyName(e.target.value);
                    }}
                />
                <TextField
                    required
                    fullWidth
                    multiline
                    inputProps={{ maxLength: 2047 }}
                    rows={6}
                    margin="dense"
                    value={comment}
                    label={<FormattedMessage id="api-key.comment" />}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage id="keys.add.cancel" />
                </Button>
                <Button variant="contained" onClick={createKey}>
                    <FormattedMessage id="keys.add" />
                </Button>
            </DialogActions>
        </>
    );
};

export default AddKeyPanel;
