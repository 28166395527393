import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import type { WFState } from '@realcity/web-frame';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useGetKeysQuery } from '../../graphql/graphql-typings';
import AddKeyDialog from './AddKeyDialog';
import KeyList from './KeyList';

const useStyles = makeStyles({
    titleRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
});

const Keys: React.FC = () => {
    const keycloak = useSelector((state: WFState) => state.auth.keycloak);
    const authenticated = useSelector((state: WFState) => state.user.authenticated);
    const queryResult = useGetKeysQuery({ skip: !keycloak || !authenticated });

    const [dialogOpen, setDialogOpen] = useState(false);
    const classes = useStyles();

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const handleUpdate = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryResult.refetch();
    };

    if (!keycloak) {
        return (
            <CircularProgress />
        );
    }

    return (
        <>
            <div className={classes.titleRow}>
                <Typography variant="h1">
                    <FormattedMessage id="keys.title" />
                </Typography>

                {authenticated && (
                    <Button variant="contained" onClick={openDialog}>
                        <FormattedMessage id="keys.add-new" />
                    </Button>
                )}
            </div>

            {authenticated ? (
                <>
                    <KeyList {...queryResult} />
                    <AddKeyDialog open={dialogOpen} onClose={closeDialog} onCreated={handleUpdate} />
                </>
            ) : (
                <Typography>
                    <FormattedMessage id="keys.authentication-required" />
                </Typography>
            )}
        </>
    );
};

export default Keys;
