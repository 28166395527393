import Warning from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import type { useGetKeysQuery, UserApiKeyState } from '../../graphql/graphql-typings';
import DeleteKeyButton from './DeleteKeyButton';

const KeyList: React.FC<ReturnType<typeof useGetKeysQuery>> = ({ loading, data, refetch }) => {
    if (loading) {
        return (
            <CircularProgress />
        );
    }

    if (!data) {
        return (
            <Warning />
        );
    }

    if (data.keys.length === 0) {
        return (
            <Typography>
                <FormattedMessage id="keys.no-items" />
            </Typography>
        );
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <FormattedMessage id="api-key.key" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="api-key.company-name" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="api-key.comment" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="api-key.created-time" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="api-key.state" />
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.keys.map(key => (
                    <TableRow key={key.key}>
                        <TableCell sx={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>
                            {key.key}
                        </TableCell>
                        <TableCell>
                            {key.companyName}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
                            {key.comment}
                        </TableCell>
                        <TableCell>
                            <FormattedDate value={key.createdTime} />
                            {' '}
                            <FormattedTime value={key.createdTime} />
                        </TableCell>
                        <TableCell>
                            {stateMessage(key.state)}
                        </TableCell>
                        <TableCell align="right">
                            <DeleteKeyButton keyToDelete={key.key} onDelete={async () => refetch()} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

function stateMessage(state: UserApiKeyState): ReactNode {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (state) {
        case 'ACTIVATED':
            return (
                <FormattedMessage id="api-key.state.activated" />
            );
        case 'PENDING':
            return (
                <FormattedMessage id="api-key.state.pending" />
            );
        case 'BLOCKED':
            return (
                <FormattedMessage id="api-key.state.blocked" />
            );
    }
}

export default KeyList;
