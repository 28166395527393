import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AddKeyPanel from './AddKeyPanel';

interface Props {
    open: boolean;
    onClose: () => void;
    onCreated: () => void;
}

const AddKeyDialog: React.FC<Props> = ({ open, onClose, onCreated }) => (
    <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
            <FormattedMessage id="keys.add.title" />
        </DialogTitle>
        <AddKeyPanel onClose={onClose} onCreated={onCreated} />
    </Dialog>
);

export default AddKeyDialog;
