import homeHu from './hu/Home.md';
import dataSourcesHu from './hu/DataSources.md';
import homeEn from './en/Home.md';
import dataSourcesEn from './en/DataSources.md';
import APP_CONFIG from '../config';
import baseMessagesEn from './en.json';
import baseMessagesHu from './hu.json';

const messagesHu = {
    ...baseMessagesHu,
    'privacy-policy-link': APP_CONFIG.PRIVACY_POLICY_URL_HU,
    'home.content': homeHu,
    'data-sources.content': dataSourcesHu,
};

const messagesEn = {
    ...baseMessagesEn,
    'privacy-policy-link': APP_CONFIG.PRIVACY_POLICY_URL_EN,
    'home.content': homeEn,
    'data-sources.content': dataSourcesEn,
};

type Messages = typeof messagesEn & typeof messagesHu;
export type MessageKeys = keyof Messages;

// force TypeScript to check if every id is present in every language
const hu: Messages = messagesHu;
const en: Messages = messagesEn;

const messages = {
    hu,
    en,
};

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace FormatjsIntl {
        interface Message {
            ids: MessageKeys;
        }
    }
}

export default messages;
