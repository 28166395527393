import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
};

export type CreateApiKeyInput = {
  comment: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createKey: UserApiKey;
  deleteKey?: Maybe<Scalars['String']>;
};


export type MutationCreateKeyArgs = {
  options: CreateApiKeyInput;
};


export type MutationDeleteKeyArgs = {
  key: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  keys: Array<UserApiKey>;
};

export type UserApiKey = {
  __typename?: 'UserApiKey';
  comment: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  createdTime: Scalars['DateTime'];
  key: Scalars['String'];
  state: UserApiKeyState;
};

export enum UserApiKeyState {
  Activated = 'ACTIVATED',
  Blocked = 'BLOCKED',
  Pending = 'PENDING'
}

export type GetKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKeysQuery = { __typename?: 'Query', keys: Array<{ __typename?: 'UserApiKey', key: string, companyName?: string | null | undefined, comment: string, createdTime: string, state: UserApiKeyState }> };

export type CreateKeyMutationVariables = Exact<{
  options: CreateApiKeyInput;
}>;


export type CreateKeyMutation = { __typename?: 'Mutation', createKey: { __typename?: 'UserApiKey', key: string } };

export type DeleteKeyMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type DeleteKeyMutation = { __typename?: 'Mutation', deleteKey?: string | null | undefined };


export const GetKeysDocument = gql`
    query getKeys {
  keys {
    key
    companyName
    comment
    createdTime
    state
  }
}
    `;

/**
 * __useGetKeysQuery__
 *
 * To run a query within a React component, call `useGetKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKeysQuery(baseOptions?: Apollo.QueryHookOptions<GetKeysQuery, GetKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKeysQuery, GetKeysQueryVariables>(GetKeysDocument, options);
      }
export function useGetKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeysQuery, GetKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKeysQuery, GetKeysQueryVariables>(GetKeysDocument, options);
        }
export type GetKeysQueryHookResult = ReturnType<typeof useGetKeysQuery>;
export type GetKeysLazyQueryHookResult = ReturnType<typeof useGetKeysLazyQuery>;
export type GetKeysQueryResult = Apollo.QueryResult<GetKeysQuery, GetKeysQueryVariables>;
export const CreateKeyDocument = gql`
    mutation createKey($options: CreateApiKeyInput!) {
  createKey(options: $options) {
    key
  }
}
    `;
export type CreateKeyMutationFn = Apollo.MutationFunction<CreateKeyMutation, CreateKeyMutationVariables>;

/**
 * __useCreateKeyMutation__
 *
 * To run a mutation, you first call `useCreateKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeyMutation, { data, loading, error }] = useCreateKeyMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateKeyMutation, CreateKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKeyMutation, CreateKeyMutationVariables>(CreateKeyDocument, options);
      }
export type CreateKeyMutationHookResult = ReturnType<typeof useCreateKeyMutation>;
export type CreateKeyMutationResult = Apollo.MutationResult<CreateKeyMutation>;
export type CreateKeyMutationOptions = Apollo.BaseMutationOptions<CreateKeyMutation, CreateKeyMutationVariables>;
export const DeleteKeyDocument = gql`
    mutation deleteKey($key: String!) {
  deleteKey(key: $key)
}
    `;
export type DeleteKeyMutationFn = Apollo.MutationFunction<DeleteKeyMutation, DeleteKeyMutationVariables>;

/**
 * __useDeleteKeyMutation__
 *
 * To run a mutation, you first call `useDeleteKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeyMutation, { data, loading, error }] = useDeleteKeyMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKeyMutation, DeleteKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKeyMutation, DeleteKeyMutationVariables>(DeleteKeyDocument, options);
      }
export type DeleteKeyMutationHookResult = ReturnType<typeof useDeleteKeyMutation>;
export type DeleteKeyMutationResult = Apollo.MutationResult<DeleteKeyMutation>;
export type DeleteKeyMutationOptions = Apollo.BaseMutationOptions<DeleteKeyMutation, DeleteKeyMutationVariables>;