import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
    code: {
        padding: '2px 4px',
        backgroundColor: '#f0f0f0',
    },
});

const Code: React.FC = ({ children }) => {
    const classes = useStyles();

    return (
        <code className={classes.code}>{children}</code>
    );
};

export default Code;
