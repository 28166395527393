import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import type { MessageKeys } from './translations';

const useFetchText = (url: string) => {
    const [value, setValue] = useState<string | null>(null);

    useEffect(() => {
        let isCancelled = false;

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        (async () => {
            const res = await fetch(url);
            const text = await res.text();

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!isCancelled) {
                setValue(text);
            }
        })();

        return () => {
            isCancelled = true;
        };
    }, [url]);

    return value;
};

// eslint-disable-next-line import/prefer-default-export
export const useLocalizedFileContent = (id: MessageKeys, values?: { [key: string]: any }) => {
    const intl = useIntl();
    const text = useFetchText(
        intl.formatMessage({
            id,
        }),
    );

    if (!text) {
        return null;
    }

    return intl.formatters.getMessageFormat(text).format(values) as string;
};
