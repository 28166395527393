import { getConfig } from '@realcity/web-frame/lib/config';

interface AppConfig {
    API_BASE_URL: string;
    PRIVACY_POLICY_URL_HU: string;
    PRIVACY_POLICY_URL_EN: string;
}

const APP_CONFIG = getConfig<AppConfig>();
export default APP_CONFIG;
