import Box from '@mui/material/Box';
import React from 'react';

const ContentContainer: React.FC = ({ children }) => (
    <Box sx={{ maxWidth: '66%' }}>
        {children}
    </Box>
);

export default ContentContainer;
