import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import type { Options } from 'react-markdown/lib/ast-to-react';
import { Link as RouterLink } from 'react-router-dom';
import Code from './Code';

const COMPONENTS: Options['components'] = {
    p: ({ children }) => (
        <Typography variant="body1" sx={{ marginY: 1 }}>{children}</Typography>
    ),
    a: ({ children, href }) => {
        if (href?.startsWith('/')) {
            return (
                <Link component={RouterLink} to={href}>{children}</Link>
            );
        }
        return (
            <Link href={href} target="_blank" rel="noopener noreferrer">{children}</Link>
        );
    },
    code: Code,
    h1: createHeading('h1'),
    h2: createHeading('h2'),
    h3: createHeading('h3'),
    h4: createHeading('h4'),
    h5: createHeading('h5'),
    h6: createHeading('h6'),
};

function createHeading(tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'): React.FC {
    return ({ children, ...rest }) => (
        <Typography variant={tag} {...rest}>{children}</Typography>
    );
}

const MuiReactMarkdown = ({ children }: { children: string | null }) => {
    if (children === null) {
        return <CircularProgress />;
    }

    return (
        <ReactMarkdown components={COMPONENTS}>
            {children}
        </ReactMarkdown>
    );
};

export default MuiReactMarkdown;
