import Source from '@mui/icons-material/SourceOutlined';
import VpnKey from '@mui/icons-material/VpnKeyOutlined';
import { SidebarItem } from '@realcity/web-frame/lib/components/Sidebar/SidebarItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const MainMenu: React.FunctionComponent = () => (
    <>
        <SidebarItem icon={Source} text={<FormattedMessage id="data-sources.title" />} path="/data-sources" />
        <SidebarItem icon={VpnKey} text={<FormattedMessage id="keys.title" />} path="/keys" />
        {/* TODO <SidebarItem icon={Copyright} text={<FormattedMessage id="copyright.title"/>} path="/copyright"/> */}
    </>
);

export default MainMenu;
