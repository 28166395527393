import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
    confirmMessage: ReactNode;
    onConfirm: () => void;
}

const ConfirmButton: React.FC<Props> = ({ confirmMessage, onConfirm, children }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);

    const openConfirmDialog = () => {
        setConfirmOpen(true);
    };

    const closeConfirmDialog = () => {
        setConfirmOpen(false);
    };

    return (
        <>
            <Button onClick={openConfirmDialog}>
                {children}
            </Button>
            <Dialog open={confirmOpen} onClose={closeConfirmDialog}>
                <DialogContent>
                    {confirmMessage}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmDialog}>
                        <FormattedMessage id="confirmation.cancel" />
                    </Button>
                    <Button variant="contained" onClick={onConfirm}>
                        <FormattedMessage id="confirmation.ok" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmButton;
